import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>
    Contact
    </h1>
    <strong>Email:</strong> <a href="mailto:kriskomar@gmail.com">kriskomar@gmail.com</a><br />
    <strong>Phone (call or text):</strong> 208.559.4595<br />
    <strong>Instagram:</strong> <a href="https://www.instagram.com/kriskomarphotography/">kriskomarphotography</a>
    <p>{`Originally from San Diego, CA, now married with 2 twin boys and settled in Kuna, ID, I finally got the courage
to start my own photography company. I've had a passion for striking images and film my entire life, but I
spent a great deal of time using the analytical and logical side of the brain as a software developer for
Scentsy, Inc. and a few other companies. I get very excited about photography because aside from being an art
form, it is intensely scientific and there is a lot of information to be learned. In photography one must be
naturally artistic to be able to identify and frame a great image, yet also understand the science behind
the equipment that is taking the photo. Only then can a truly beautiful image be captured. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      